<template>
  <div class="abt-member bf-op">
    <section class="abt-member__top">
      <div class="abt-member__top__label">
        <label>{{ title }}</label>
      </div>

      <em @click="sortThis">{{ $t("rank.rankSort") }}</em>
    </section>

    <!--<section class="abt-member__center">
      <div class="abt-member__search">
        <img src="@/src/assets/common/search.png" @click="search" />
        <input
          type="text"
          :placeholder="searchPlace"
          v-model="searchParams.keywords"
          v-on:keyup.enter="search"
        />
      </div>
    </section>-->

    <section class="abt-member__bottom">
      <div
        class="abt-member__bottom__ul"
        v-if="dataList && dataList.length > 0"
        v-infinite-scroll="loadMoreList"
        :infinite-scroll-distance="10"
        :infinite-scroll-disabled="!allowLoad"
        ref="ulScroll"
      >
        <div
          class="abt-member__bottom__li"
          v-for="(item, index) in dataList"
          :key="'abt-bottom' + index"
          :class="{ selected: item.selected }"
          @click="viewDetail(item, index)"
        >
          <div class="abt-bottom__image">
            <img :src="item.photo || defaultHeadImage" alt="" />
          </div>

          <div class="abt-bottom__detail">
            <em>{{
              $i18n.locale === "cn"
                ? item.cname || item.ename
                : item.ename || item.cname
            }}</em>
            <p>
              {{
                $i18n.locale === "cn"
                  ? item.jobPositionCn || item.jobPosition
                  : item.jobPosition || item.jobPositionCn
              }}
            </p>
          </div>

          <!--<div class="abt-bottom__report">
            {{ item.reportNum }}
            {{ $t("ability.reportNum") }}
          </div>-->
        </div>

        <section v-if="moreLoadingStatus">
          <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
            >&nbsp;</van-loading
          >
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "memberSquare",
  props: ["title", "searchPlace", "dataList", "allowLoad", "moreLoadingStatus"],
  data() {
    return {
      searchParams: {
        keywords: "",
        sortedKey: "NAME",
        sorted: "asc",
      },
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
    }),
  },
  methods: {
    sortThis() {
      this.searchParams.sorted === "asc"
        ? (this.searchParams.sorted = "desc")
        : (this.searchParams.sorted = "asc");

      let params = {
        type: "init",
        data: this.searchParams,
      };

      this.$emit("search", params);
    },
    search() {
      let params = {
        type: "init",
        data: this.searchParams,
      };

      this.$emit("search", params);
    },
    loadMoreList() {
      let params = {
        type: "more",
        data: this.searchParams,
      };

      this.$emit("search", params);
    },
    viewDetail(data, index) {
      let params = {
        data: data,
        index: index,
      };
      this.$emit("viewDetail", params);
    },
    sc0() {
      this.$refs.ulScroll.scrollTo(0, 0);
    },
  },
  mounted() {
    /*if (this.dataList && this.dataList.length > 0) {
      this.viewDetail(this.dataList[0], 0);
    }*/
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.abt-member {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: toPad(10);
  flex-direction: column;
}

.abt-member__top {
  position: relative;
  display: flex;
  width: 100%;
  padding: toPad(20);
  align-items: center;
  line-height: 1;
  flex-shrink: 0;
  z-index: 0;
  font-family: JLREmeric;

  .abt-member__top__label {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    justify-content: flex-start;
    line-height: 1.4;
  }

  label {
    font-size: toPad(20);
    font-weight: 600;
  }
  i {
    display: inline-block;
    width: toPad(16);
    height: toPad(16);
    cursor: pointer;
    background: url("~@/src/assets/rank/question.png") no-repeat center center;
    background-size: 100% 100%;
    margin-left: toPad(10);
    margin-right: toPad(10);
    flex-shrink: 0;
  }
  em {
    display: inline-block;
    min-height: toPad(16);
    flex-shrink: 0;
    text-align: right;
    font-size: toPad(14);
    padding-right: toPad(20);
    background: url("~@/src/assets/rank/sort.png") no-repeat right center;
    background-size: auto toPad(16);
    cursor: pointer;
    font-style: normal;
  }
}

.abt-member__center {
  position: relative;
  width: 100%;
  padding: 0 toPad(20) toPad(15) toPad(20);
  flex-shrink: 0;
}
.abt-member__search {
  position: relative;
  display: flex;
  width: 100%;
  height: toPad(31);
  max-width: toPad(484);
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  background: rgba(217, 217, 217, 0.3);
  border-radius: toPad(31);
  overflow: hidden;

  img {
    position: absolute;
    z-index: 2;
    width: toPad(14);
    height: toPad(14);
    left: toPad(11);
    top: 50%;
    transform: translateY(-50%);
  }
  input {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 0 toPad(18) 0 toPad(30);
    border: 0;
    font-size: toPad(14);
    background: transparent;

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.abt-member__bottom {
  position: relative;
  width: 100%;
  flex-basis: 100%;
  overflow: hidden;
  padding: 0 toPad(20) toPad(20) toPad(20);

  .abt-member__bottom__ul {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: toPad(2);

    .abt-member__bottom__li {
      position: relative;
      width: 100%;
      border-radius: toPad(8);
      cursor: pointer;
      display: flex;
      padding: toPad(10) 0;
      align-items: center;

      &.selected {
        background: url("~@/src/assets/common/member-select.png") no-repeat 0 0;
        background-size: cover;
        color: #fff;
        padding-right: toPad(48);
      }
    }
    .abt-bottom__image {
      flex-shrink: 0;
      width: toPad(50);
      height: toPad(50);
      border-radius: 100%;
      overflow: hidden;
      margin-left: toPad(10);

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .abt-bottom__detail {
      flex-basis: 100%;
      padding: 0 toPad(10) 0 toPad(20);
      em {
        font-size: toPad(18);
        font-style: normal;
      }

      p {
        font-size: toPad(13);
        line-height: 1.3;
        opacity: 0.5;
      }
    }
    .abt-bottom__report {
      display: inline-flex;
      flex-shrink: 0;
      opacity: 0.5;
    }
  }
}
</style>
