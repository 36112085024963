<template>
  <section class="totalContent-edp-out">
    <div
      class="totalContent-edp-flex"
      v-show="pageDetailHide"
      v-if="refreshStatus"
    >
      <edp-header-search
        :headerLeftWord="$t('milepostReport.headerLeftWord')"
        :searchPlaceHolder="$t('milepostReport.searchPlaceholder')"
        @search="searchFn"
      ></edp-header-search>

      <div
        class="edpAbility-out edp-ability"
        v-if="pageList && pageList.length > 0"
      >
        <div class="edpAbility-out__left">
          <member-square
            :title="$t('ability.memberTitle')"
            :searchPlace="$t('ability.searchPlaceholder')"
            :dataList="pageList"
            :allowLoad="moreLoadingPd"
            :moreLoadingStatus="moreLoadingStatus"
            @viewDetail="viewDetailFn"
            @search="memberSearchFn"
            ref="memberSquareRef"
          />
        </div>

        <div class="edpAbility-out__right">
          <!--right in-->
          <div class="edp-ability__list">
            <div class="edp-ability__list__item bf-op" ref="rightDetail">
              <!-- edp-ability__list__detail -->
              <section class="edp-ability__list__detail">
                <div class="edp-ability__list__detail__img">
                  <img
                    :src="memberReportJson.photo || defaultHeadImage"
                    alt=""
                    @click="linkMember(memberReportJson)"
                  />
                </div>

                <div class="edp-ability__list__detail__info">
                  <div class="ealdi-name" @click="linkMember(memberReportJson)">
                    <span v-if="$i18n.locale === 'cn'">{{
                      $i18n.locale === "cn"
                        ? memberReportJson.cname || memberReportJson.ename
                        : memberReportJson.ename || memberReportJson.cname
                    }}</span>
                    <span v-else>{{
                      memberReportJson.ename || memberReportJson.cname
                    }}</span>

                    <em v-if="memberReportJson.empGenerations"
                      ><span>{{ memberReportJson.empGenerations }}</span></em
                    >
                  </div>

                  <!--<ul class="ealdi-tags">
                  <li
                    v-for="(itemTags, indexTags) in memberReportJson.tags"
                    :key="'ealdiTags' + indexTags"
                  >
                    {{ itemTags }}
                  </li>
                </ul>-->

                  <ul class="ealdi-details">
                    <li>
                      <label>{{ $t("ability.infoTitle4") }}</label>
                      <p>
                        {{
                          utils.formatLang(
                            $i18n.locale,
                            memberReportJson.dept,
                            memberReportJson.deptEname
                          )
                        }}
                      </p>
                    </li>

                    <li>
                      <label>{{ $t("ability.infoTitle5") }}</label>
                      <p>{{ memberReportJson.subdivision }}</p>
                    </li>

                    <li>
                      <label>{{ $t("ability.infoTitle6") }}</label>
                      <p>
                        {{
                          utils.formatLang(
                            $i18n.locale,
                            memberReportJson.directLeaderChineseName,
                            memberReportJson.directLeaderEnName
                          )
                        }}
                      </p>
                    </li>

                    <li>
                      <label>{{ $t("ability.infoTitle7") }}</label>
                      <p>
                        {{
                          utils.formatLang(
                            $i18n.locale,
                            memberReportJson.jobPositionCn,
                            memberReportJson.jobPosition
                          )
                        }}
                      </p>
                    </li>
                  </ul>
                </div>
              </section>
              <!-- edp-ability__list__detail end -->

              <div class="edp-changeReportType">
                <div class="edp-changeReportType__title">
                  {{ $t("ability.changeTypeTitle") }}
                </div>

                <div class="edp-selectComp">
                  <section
                    class="edp-selectComp__title"
                    @click="selectChangeVisible_show"
                  >
                    {{ nowType }}
                  </section>

                  <ul
                    class="edp-selectComp__content"
                    v-if="selectChangeVisible"
                  >
                    <li
                      v-for="(item, index) in cateList"
                      :key="'milepostReport__cate' + index"
                      :class="{ selected: item.selected }"
                      @click="changeCateType(item.type)"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- edp-ability__list__report -->
              <section
                class="edp-ability__list__report-out"
                v-if="
                  memberReportJson.report && memberReportJson.report.length > 0
                "
              >
                <section
                  class="edp-ability__list__report"
                  v-for="(itemReport, indexReport) in memberReportJson.report"
                  :key="'itemReport' + indexReport"
                >
                  <dl style="width: 8%">
                    <dt>{{ $t("milepostReport.tableTitle1") }}</dt>
                    <dd>{{ indexReport + 1 }}</dd>
                  </dl>
                  <dl style="width: 35%">
                    <dt>{{ $t("milepostReport.tableTitle2") }}</dt>
                    <dd>
                      {{
                        ($i18n.locale === "cn" &&
                          (pageType == 1 || pageType == 2)) ||
                        pageType == 1
                          ? itemReport.yearCount
                          : pageType == 3
                          ? itemReport.currentYear
                          : utils.stNdTh(itemReport.yearCount)
                      }}
                      <span v-if="pageType == 1">{{
                        $t("milepostReport.cate1")
                      }}</span
                      ><span v-if="pageType == 2">{{
                        $t("milepostReport.cate2")
                      }}</span>
                      <span v-if="pageType == 3">{{
                        $t("milepostReport.cate3")
                      }}</span>
                    </dd>
                  </dl>

                  <dl style="width: 18%">
                    <dt>{{ $t("milepostReport.tableTitle3") }}</dt>
                    <dd>
                      {{
                        pageType == 3
                          ? itemReport.birthday
                          : itemReport.reportGenTime
                      }}
                    </dd>
                  </dl>

                  <dl class="fBasis100">
                    <dd>
                      <span
                        class="edp-ability__list__report__btn"
                        @click="viewReport(itemReport)"
                        >{{ $t("milepostReport.tableButton1") }}</span
                      >
                    </dd>
                  </dl>
                </section>
              </section>

              <section class="report-noResult" v-else>
                {{ $t("ability.resultNull") }}
              </section>
              <!-- edp-ability__list__report end -->
            </div>
          </div>
          <!--right in end-->
        </div>
      </div>

      <div class="user-empty-common tac" v-else>
        <img src="@/src/assets/member/user-empty.png" alt="" class="mb16" />
        <p class="f14 tac">{{ $t("departmentList.resultNull") }}</p>
      </div>

      <van-overlay
        :show="subLoading"
        z-index="120"
        :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
      >
        <div class="loading-wrap-common">
          <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
            >&nbsp;</van-loading
          >
        </div>
      </van-overlay>
    </div>

    <router-view v-if="!pageDetailHide" />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import memberSquare from "../components/ability/memberSquare.vue";

export default {
  components: { memberSquare },
  name: "milepostReport",
  data() {
    return {
      subLoading: false,
      pageList: [],
      cateList: [],

      pageType: 1,

      moreLoadingPd: false,
      moreLoadingStatus: false,

      pageNum: 1,
      pageSize: 20,

      keyWords: "",
      sorted: "asc",
      memberReportJson: {},

      nowType: "",
      selectChangeVisible: false,

      pageDetailHide: true,
      pageFirstVisit: true,
      refreshStatus: false,
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
    }),
  },
  watch: {
    $route(val) {
      if (val.path.indexOf("memberDetail") != -1) {
        this.pageDetailHide = false;
      } else {
        this.pageDetailHide = true;

        if (this.pageFirstVisit) {
          this.pageFirstVisit = false;
          this.refreshStatus = true;

          this.initNew();
        }
      }
    },
  },
  methods: {
    ...mapActions({
      reportMiles: "reportCover/reportMiles",
      empList: "reportCover/milesReportMemberList",
    }),
    searchFn(data) {
      this.keyWords = data.searchName;

      this.nowType = this.cateList[0].name;

      this.pageInit(1);
    },
    async pageInit(num, typeMore) {
      if (num) {
        this.pageNum = num;
      }

      this.moreLoadingStatus = true;
      this.moreLoadingPd = false;

      let params = {
        current: this.pageNum,
        size: this.pageSize,
        keywords: this.keyWords,
        sortedKey: "NAME",
        sorted: this.sorted,
      };
      let res = await this.empList(params);

      let dataResLs =
        res.data.data && res.data.data.records ? res.data.data.records : [];

      if (!typeMore) {
        this.pageList = dataResLs;
      } else {
        this.pageList = this.pageList.concat(dataResLs);
      }

      this.moreLoadingStatus = false;

      if (this.pageNum * this.pageSize <= res.data.data.total) {
        this.moreLoadingPd = true;
      }

      if (this.pageNum == 1 && this.pageList && this.pageList.length > 0) {
        let firstAry = JSON.parse(JSON.stringify(this.pageList[0]));

        setTimeout(() => {
          this.$refs.memberSquareRef.sc0();
          this.$refs.memberSquareRef.viewDetail(firstAry, 0);
        }, 0);
      }
    },
    memberSearchFn(data) {
      this.sorted = data.data.sorted;

      if (data.type === "more") {
        this.pageInit(this.pageNum + 1, "more");
      } else {
        this.pageInit(1);
      }
    },
    async viewDetailFn(data) {
      this.subLoading = true;

      this.cateList.forEach((val, e) => {
        if (val.type == this.pageType) {
          this.nowType = val.name;
        }
      });

      this.pageList.forEach((val) => {
        if (val.cdsid === data.data.cdsid) {
          this.$set(val, "selected", true);
        } else {
          this.$set(val, "selected", false);
        }
      });

      let res = await this.reportMiles(data.data.cdsid);

      this.memberReportJson = res.data.data;

      this.changeCateType(this.pageType);

      this.$refs.rightDetail.scrollTo(0, 0);

      this.subLoading = false;

      this.$forceUpdate();
    },
    changeCateType(pageType) {
      this.pageType = pageType;

      this.cateList.forEach((val, e) => {
        if (val.type == pageType) {
          val.selected = true;
          this.nowType = val.name;
        } else {
          val.selected = false;
        }
      });
      this.memberReportJson.report =
        this.pageType == 1
          ? JSON.parse(JSON.stringify(this.memberReportJson.empYearReports))
          : this.pageType == 2
          ? JSON.parse(JSON.stringify(this.memberReportJson.empYearAnniversary))
          : JSON.parse(JSON.stringify(this.memberReportJson.empBirthdays));

      this.$forceUpdate();
    },
    viewReport(data) {
      if (this.pageType == 1) {
        this.$router.push({
          name: "reportCover",
          query: { onlyId: data.onlyId },
        });
      } else if (this.pageType == 2) {
        this.$router.push({
          name: "entryReport",
          query: { onlyId: data.onlyId },
        });
      } else {
        this.$router.push({
          name: "birthdayReport",
          query: { onlyId: data.onlyId },
        });
      }
    },
    selectChangeVisible_show(e) {
      e.stopPropagation();

      this.selectChangeVisible = true;
    },
    linkMember(item) {
      let sercetCd = this.utils.encryption(item.cdsid);

      this.$router.push({
        path: "/milepostReport/memberDetail",
        query: { id: sercetCd },
      });
    },
    initNew() {
      let _this = this;

      this.pageType =
        this.$route.query.type &&
        (this.$route.query.type == 1 ||
          this.$route.query.type == 2 ||
          this.$route.query.type == 3)
          ? this.$route.query.type
          : 1;

      this.cateList =
        process.env.VUE_APP_MODES != "prod"
          ? [
              {
                name: this.$t("milepostReport.cate1"),
                selected: false,
                type: 1,
              },
              {
                name: this.$t("milepostReport.cate2"),
                selected: false,
                type: 2,
              },
              {
                name: this.$t("milepostReport.cate3"),
                selected: false,
                type: 3,
              },
            ]
          : [
              {
                name: this.$t("milepostReport.cate2"),
                selected: false,
                type: 2,
              },
              {
                name: this.$t("milepostReport.cate3"),
                selected: false,
                type: 3,
              },
            ];

      this.pageInit(1);

      $(document).on("click", function () {
        _this.selectChangeVisible = false;
      });
    },
  },
  mounted() {
    if (this.$route.path.indexOf("memberDetail") != -1) {
      this.pageDetailHide = false;
    } else {
      this.pageDetailHide = true;

      if (this.pageFirstVisit) {
        this.pageFirstVisit = false;
        this.refreshStatus = true;

        this.initNew();
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/src/styles/ability.scss";
</style>
